setTimeout(function(){
    $.fancybox.open({
        src  : '#modal-promo',
        type : 'inline',
        touch: false
    });
    $("#modal-promo").find(".elm_text").removeClass("mod--hack");
    $(document).on("click","#modal-promo [data-more]",function () {
        var $elm = $(this).parents(".col"),
            $text = $("#modal-promo").find(".elm_text_1"),
            $text2 = $("#modal-promo").find(".elm_text_2");

        $elm.fadeOut(500,"linear",function () {
            $elm.next("div").removeClass("col-6").addClass("col-12");
            $text2.animate({
                height: $text2.get(0).scrollHeight
            }, 1000, function(){
                $text2.height('auto');
            });
        });
    });
},10000);
